@import url('https://fonts.googleapis.com/css2?family=Allerta&family=Gothic+A1&family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Gothic A1", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.oneinput{
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

::placeholder {
  color: #fff;

}
.footer.css-1wuw8dw-MuiBreadcrumbs-separator{
  color: white;
  background: rgba( 255, 255, 255, 0.5 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 20px );
-webkit-backdrop-filter: blur( 20px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
}


.swiper {
  width: 50%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}
.swiper-slide {
  background-size: cover;
  background-position: center;
}
.mySwiper2 {
  height: 50%;
  width: 80%;
}
.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}
.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}
.swiper-wrapper {
  margin-top: 30px;
}
.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-button-next{
  display: none;
}